.Header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 3%;
    padding-right: 3%;
    font-size: 24px;
    color: white;
    gap: 1rem;
}

.leftheader {
    flex: 0.5;
    display: flex;
    align-items: center;

}

.centerheader {
    display: flex;
    flex: 3;
    align-items: center;
    justify-content: center;
}

.rightheader {
    display: flex;
    gap: 1rem;
    flex: 1.5;
    align-items: center;
    justify-content: right;
}

.small-image {
    width: 90px;
    height: 90px;
}

@media (max-width: 900px) {
    .Header {
        padding-bottom: 3%;
        flex-direction: column;
    }

    .rightheader {
        justify-content: center;
    }

    .leftheader {
        flex-direction: column;
        justify-content: center;
    }
}