@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap);

.App {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    font-family: 'Poppins', sans-serif;
}
.Header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 3%;
    padding-right: 3%;
    font-size: 24px;
    color: white;
    grid-gap: 1rem;
    gap: 1rem;
}

.leftheader {
    flex: 0.5 1;
    display: flex;
    align-items: center;

}

.centerheader {
    display: flex;
    flex: 3 1;
    align-items: center;
    justify-content: center;
}

.rightheader {
    display: flex;
    grid-gap: 1rem;
    gap: 1rem;
    flex: 1.5 1;
    align-items: center;
    justify-content: right;
}

.small-image {
    width: 90px;
    height: 90px;
}

@media (max-width: 900px) {
    .Header {
        padding-bottom: 3%;
        flex-direction: column;
    }

    .rightheader {
        justify-content: center;
    }

    .leftheader {
        flex-direction: column;
        justify-content: center;
    }
}
.Main{
    display: flex;
    flex:0 1;
    width: 100%;
}
.Home {
    display: flex;
    flex-direction: column;
    width: 100%;
} 
.description-block{
    margin-left: 3%;
}
.special-font{
    color: goldenrod;
}
.title-font {
    margin-top: 10px;
    font-size: clamp(1rem, 2rem, 4rem);
}

.txt-underline {
    text-decoration: underline;
}

.dims2d3d{
    padding: 2%;
    display: flex;
    flex-direction:row;
    align-items: center;
    justify-content: space-evenly;
}

.dim2d{
    padding-bottom: 1%;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 1;
}

.img-2d{
    flex-wrap: wrap;
    width: 400px;
    min-height: 400px;
    border-radius: 10%;
    transition: .5s ease; 
    -webkit-backface-visibility: hidden; 
            backface-visibility: hidden;
}

.img-2d:hover{
    opacity: 0.3;

}


.dim3d{
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 1;
}
.FAQ{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-left: 10%;
    padding-right: 10%;
}
.team{
    display: flex;
    align-items: center;
    justify-content: center;
}
.home-collapse{
    border: 1px solid black;
    border-radius: 2%;
    width: 100%;
    display: flex;
    justify-content: left;
    align-items: center;
    margin-bottom: 5px;
}

.collapse-text{
    display: flex;
    flex-direction: column;
}
.roadmap-text{
    width: 30%;
}
.roadmap-flex{
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}
.roadmap{
    padding-top: 3%;
    text-align: center;
    display: flex;
    flex-direction: column;
}
.roadmapimg{
    align-self: center;
    width: 400px;
}
@media (max-width:400px) {
    .img-2d{
        width: 100%;
    }    
}
@media (max-width:900px){
    .dims2d3d{
        flex-direction: column;
    }
    .roadmap-flex{
        padding-left: 2%;
        flex-direction: column;
    }
    .roadmap-text{
        width: 100%;
    }
}

.titleRoadMap{
    font-size: x-large;
}
.mintcontainer{
    padding: 2%;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    grid-gap: 50px;
    gap: 50px;
}
@media (max-width:1000px){
    .amount-cost{
        flex-direction: column;
        grid-gap: 20px;
        gap: 20px;
    }

}
.Footer{
    justify-content: space-between;
    display: flex;
    padding-top: 15px;
    padding-bottom: 15px;
    padding-left: 3%;
    padding-right:3%;
}


.leftfooter{
    display: flex;
    flex:1 1;
}

.centerfooter{
    display: flex;
    flex:1 1;
}

.rightfooter{
    flex:1 1;
    display: flex;
    font-size: 30px;
    grid-gap: 0.4em;
    gap: 0.4em;
    justify-content: right;
}
