.Footer{
    justify-content: space-between;
    display: flex;
    padding-top: 15px;
    padding-bottom: 15px;
    padding-left: 3%;
    padding-right:3%;
}


.leftfooter{
    display: flex;
    flex:1;
}

.centerfooter{
    display: flex;
    flex:1;
}

.rightfooter{
    flex:1;
    display: flex;
    font-size: 30px;
    gap: 0.4em;
    justify-content: right;
}