.mintcontainer{
    padding: 2%;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    gap: 50px;
}
@media (max-width:1000px){
    .amount-cost{
        flex-direction: column;
        gap: 20px;
    }

}