.Home {
    display: flex;
    flex-direction: column;
    width: 100%;
} 
.description-block{
    margin-left: 3%;
}
.special-font{
    color: goldenrod;
}
.title-font {
    margin-top: 10px;
    font-size: clamp(1rem, 2rem, 4rem);
}

.txt-underline {
    text-decoration: underline;
}

.dims2d3d{
    padding: 2%;
    display: flex;
    flex-direction:row;
    align-items: center;
    justify-content: space-evenly;
}

.dim2d{
    padding-bottom: 1%;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 1;
}

.img-2d{
    flex-wrap: wrap;
    width: 400px;
    min-height: 400px;
    border-radius: 10%;
    transition: .5s ease; 
    backface-visibility: hidden;
}

.img-2d:hover{
    opacity: 0.3;

}


.dim3d{
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 1;
}
.FAQ{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-left: 10%;
    padding-right: 10%;
}
.team{
    display: flex;
    align-items: center;
    justify-content: center;
}
.home-collapse{
    border: 1px solid black;
    border-radius: 2%;
    width: 100%;
    display: flex;
    justify-content: left;
    align-items: center;
    margin-bottom: 5px;
}

.collapse-text{
    display: flex;
    flex-direction: column;
}
.roadmap-text{
    width: 30%;
}
.roadmap-flex{
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}
.roadmap{
    padding-top: 3%;
    text-align: center;
    display: flex;
    flex-direction: column;
}
.roadmapimg{
    align-self: center;
    width: 400px;
}
@media (max-width:400px) {
    .img-2d{
        width: 100%;
    }    
}
@media (max-width:900px){
    .dims2d3d{
        flex-direction: column;
    }
    .roadmap-flex{
        padding-left: 2%;
        flex-direction: column;
    }
    .roadmap-text{
        width: 100%;
    }
}

.titleRoadMap{
    font-size: x-large;
}